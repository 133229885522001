/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import {
  LaunchStatusType,
  PlatformNext,
  UndefinedModelDefinition,
  DefaultStreamerOptions,
  StreamerStatus
} from "@pureweb/platform-sdk"

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  VideoStream,
  System,
} from "@pureweb/platform-sdk-react"
import * as qs from "query-string"
import React, { useEffect, useState, useRef } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Button, ButtonGroup, Icon } from "semantic-ui-react"
import useAsyncEffect from "use-async-effect"
import "./App.css"
import clientConfig from "./client.json"

import { LaunchView } from "./Launch"
import logger from "./Log"

import { Joystick } from "react-joystick-component"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import logo from "./images/Shoe_8Bit_sm.gif";
import video from "./images/Shoe_8Bit.mp4";
import logoBlack from "./images/logo_black.png";
import logoBlack1 from "./images/logo_black1.png";


const client = clientConfig

class ClientJson { }

class ClientOptions {
  // Overridable streamer options
  ForceRelay = false

  isValid() {
    if (!this.ProjectId) {
      return false
    }
    if (!this.ModelId) {
      return false
    }
    return true
  }
}

const LoadingView = props => {


  const images = ['someImageUrl.jpg', 'someOtherImageUrl.jpg'];

  for (const image of images) {
    const imageElement = new Image();
    imageElement.src = image;
  }


  if (
    props.StreamerStatus === StreamerStatus.Connected ||
    props.StreamerStatus === StreamerStatus.Completed
  ) {
    return <div />
  }

  let content

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>
          Your browser does not support the necessary WebRTC capabilities.
        </h3>
      </div>
    )
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    )
  } else {
    console.log(props.LaunchRequestStatus.status)

    const humanReadableStatus = {
      unknown: "(1/5) Initializing your connection",
      accepted: "(2/5) Accepted, requesting model",
      queued: "(3/5) In queue",
      requested: "(4/5) Model requested",
      ready: "(5/5) Ready, 3D session launching",
      serviced: "Viewing model",
      cancelled: "Cancelled",
      modelerror: "An issue with the model has occurred",
      unavailable: "The requested model does not exist",
    };


    content = (
      <div>
        {/* <img alt="logo" preload className="logo"
          src={logo} /> */}
        <video className="logo" src={video} loop muted autoplay="true" />
        <br></br>
        <h2>HOLD TIGHT
          <br></br>
          <h4>
            {humanReadableStatus[props.LaunchRequestStatus.status]}
          </h4>
        </h2>

      </div>
    )
  }
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <div style={{ textAlign: "center" }}>{content}</div>
    </div>
  )
}

const EmbeddedView = props => {
  const videoRef = useRef(null)
  const handle = useFullScreenHandle()

  const [cameraVisible, toggleCamera] = useState(false)
  const [shareVisible, toggleShare] = useState(false)


  const [lightIsOn, toggleLight] = useState(false)
  const [soundIsMuted, toggleMute] = useState(false)

  function useKeyPressHandler(emitter) {
    const [lastKey, setLastKey] = useState(null);

    return [
      lastKey,
      function (updatedVal) {
        setLastKey((prevState) => {
          if (updatedVal != prevState) {
            console.log("Make sure to disable previous key down", prevState, updatedVal)
            if (prevState !== null) {
              emitter.EmitKeyUp(new KeyboardEvent('keyup', { keyCode: prevState }))
            }
            emitter.EmitKeyDown(new KeyboardEvent('keydown', { keyCode: updatedVal }))
          }

          return updatedVal
        });
      }
    ];
  }

  const [lastKeyUsed, setLastKeyUsed] = useKeyPressHandler(props.InputEmitter)

  async function shareImage(image) {
    const response = await fetch(image);
    const blob = await response.blob();
    const filesArray = [
      new File(
        [blob],
        'puma.png',
        {
          type: "image/png",
          lastModified: new Date().getTime()
        }
      )
    ];
    const shareData = {
      files: filesArray,
    };

    if (!navigator.canShare) {
      console.log('navigator.canShare() not supported.');
      var a = document.createElement('a');
      a.href = image;
      a.download = "puma.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      console.log('Specified data cannot be shared.');
    }
    else if (navigator.canShare(shareData)) {
      navigator.share(shareData);
      console.log('navigator.canShare() supported. We can use navigator.share() to send the data.');
    } else {
      var a = document.createElement('a');
      a.href = image;
      a.download = "puma.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      console.log('Specified data cannot be shared.');
    }
  }

  function cameraMode() {
    toggleCamera(!cameraVisible)
    console.log(cameraVisible)
  }

  function shareMode() {
    toggleShare(!shareVisible)
    console.log(shareVisible)
  }


  function sendMuteInfoToGame() {
    toggleMute(!soundIsMuted)
    audio.muted = !soundIsMuted
  }

  function onUpdateMove(e) {
    const keyCodes = {
      FORWARD: 87,
      BACKWARD: 83,
      LEFT: 65,
      RIGHT: 68,
    };
    setLastKeyUsed(keyCodes[e.direction]);
  }

  function onStopJoystick(e) {
    setLastKeyUsed(null);
  }

  function onUpdatePanning(e) {
    props.InputEmitter.EmitMouseMove(0, 0, e.x / 50, -e.y / 50, window.innerWidth, window.innerHeight, window.screen.width, window.screen.height);
  }

  function onStartPanning() {
    props.InputEmitter.EmitMouseDown(null, 20, 50, window.innerWidth, window.innerHeight, window.screen.width, window.screen.height)
  }

  function onStopPanning() {
    props.InputEmitter.EmitMouseUp(null, 20, 50, window.innerWidth, window.innerHeight, window.screen.width, window.screen.height)
  }

  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone =
    System.Browser().os === "iOS" &&
    !window.navigator.userAgent.includes("iPad")

  return (


    <div classNam="grabbable" style={{ width: "100%", height: "100%" }}>
      <FullScreen handle={handle}>
        <IdleTimeout
          Status={props.StreamerStatus}
          WarningThreshold={300}
          ExitThreshold={120}
          WarningCallback={handle.exit}
          // TODO: How to 'close' a contribution?
          ExitCallback={() => window.location.reload()}
        />

        <LoadingView
          LaunchRequestStatus={props.LaunchRequestStatus}
          StreamerStatus={props.StreamerStatus}
        />

        <VideoStream
          VideoRef={videoRef}
          Emitter={props.InputEmitter}
          Stream={props.VideoStream}
          UseNativeTouchEvents={props.UseNativeTouchEvents}
          UsePointerLock={false}
          PointerLockRelease={false}
        />

        <div className={cameraVisible ? "CameraView" : "CameraView hidden"}>
          <div className="overlay">
            <div className="overlay-helper">
              <div className="overlay-element top-left">
                <span id="overlay-top-left-text" className="overlay-text">
                </span>
              </div>
              <div className="overlay-element top-right">
                <span id="overlay-top-right-text" className="overlay-text">
                </span>
              </div>
              <div className="photo-button">
                <div className="circle"></div>
                <div className="ring"></div>
              </div>
              <div className="overlay-element bottom-left">
                <span id="overlay-bottom-left-text" className="overlay-text">
                </span>
              </div>
              <div className="overlay-element bottom-right">
                <span id="overlay-bottom-right-text" className="overlay-text">
                </span>
              </div>
            </div>
          </div>
        </div>

        <ButtonGroup className="buttonGroup" >
          <Button
            circular
            onClick={handle.enter}
            className={
              isIPhone ||
                handle.active ||
                props.StreamerStatus !== StreamerStatus.Connected
                ? "hidden"
                : ""
            }
          >
            <Icon size="large" name="expand" />
          </Button>
          {/* <Button
            circular
            onClick={() => cameraMode()}
            className={
              (props.StreamerStatus !== StreamerStatus.Connected) ? "hidden" : ""
            }
          >
            <Icon name="camera" />
          </Button> */}
          <Button
            circular
            onClick={() => sendMuteInfoToGame()}
            className={
              props.StreamerStatus !== StreamerStatus.Connected ? "hidden" : ""
            }
          >
            <Icon size="large" name={!soundIsMuted ? "volume up" : "volume down"} />
          </Button>
        </ButtonGroup>
        <MobileView>
          <div
            className={
              props.StreamerStatus !== StreamerStatus.Connected
                ? "Joystick-container hidden"
                : "Joystick-container"
            }
            style={{
              position: "absolute",
              width: "100%",
              display: "inline-flex",
              justifyContent: "space-between",
              bottom: 0,
              right: 0,
              padding: 25
            }}
          >
            <div className="Joystick">
              <Joystick
                size={100}
                sticky={false}
                baseColor="rgba(0,0,0,0)"
                move={onUpdateMove}
                stop={onStopJoystick}
              ></Joystick>
            </div>

            <div className="Joystick Panning"></div>
          </div>
        </MobileView>

        {props.StreamerStatus !== StreamerStatus.Connected && null}
      </FullScreen>
    </div>
  )
}

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio()
audio.autoplay = true
audio.volume = 0.5

// Parse query parameters
const query = qs.parse(window.location.search)
const clientOptions = new ClientOptions()
clientOptions.LaunchType = query["launchType"] ?? client.launchType
if (query["collaboration"] && query["collaboration"] == "true")
  clientOptions.LaunchType = "local"

clientOptions.Endpoint = query["endpoint"] ?? client.endpoint
clientOptions.ProjectId = query["projectId"] ?? client.projectId
clientOptions.ModelId = query["modelId"] ?? client.modelId
clientOptions.Version = query["version"] ?? client.version
clientOptions.EnvironmentId = query["environmentId"] ?? client.environmentId
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query["usePointerLock"] === undefined
    ? client.usePointerLock
    : query["usePointerLock"] === "true") ?? true
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query["pointerLockRelease"] === undefined
    ? client.pointerLockRelease
    : query["pointerLockRelease"] === "true") ?? true

clientOptions.ForceRelay = query["forceRelay"] !== undefined ?? false
clientOptions.UseNativeTouchEvents =
  (query["useNativeTouchEvents"] === undefined
    ? client.useNativeTouchEvents
    : query["useNativeTouchEvents"] === "true") ?? false
// Initialize platform reference
const platform = new PlatformNext()
platform.initialize({
  endpoint: clientOptions.Endpoint || "https://api.pureweb.io"
})

const App = () => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] = useState(
    false
  )
  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition()
  )
  const [availableModels, setAvailableModels] = useState()
  const [launchRequestError, setLaunchRequestError] = useState()
  const streamerOptions = DefaultStreamerOptions

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info("Initializing available models: " + clientOptions.ProjectId)
      try {
        await platform.useAnonymousCredentials(
          clientOptions.ProjectId,
          clientOptions.EnvironmentId
        )
        await platform.connect()
        logger.info("Agent Connected: " + platform.agent.id)
        streamerOptions.iceServers =
          platform.agent.serviceCredentials.iceServers
        streamerOptions.forceRelay = clientOptions.ForceRelay
        const models = await platform.getModels()
        setAvailableModels(models)
        logger.debug("Available models", models)
      } catch (err) {
        logger.error(err)
      }
    }
  }, [clientOptions])

  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (model) {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (
            clientOptions.Version &&
            clientOptions.Version === model.version
          ) {
            return true
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true
          }
        }
        return false
      })
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0])
      } else {
        setModelDefinitionUnavailable(true)
      }
    }
  }, [availableModels])

  const launchRequestOptions = {
    regionOverride: query["regionOverride"],
    virtualizationProviderOverride: query["virtualizationProviderOverride"]
  }
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    platform,
    modelDefinition,
    launchRequestOptions
  )
  const [
    streamerStatus,
    emitter,
    videoStream,
    audioStream,
    messageSubject
  ] = useStreamer(platform, launchRequest, streamerOptions)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect()
    }
  }, [streamerStatus])

  if (audioStream) {
    audio.srcObject = audioStream
  }

  const launch = async () => {
    setLoading(true)
    audio.load()


    if (clientOptions.LaunchType !== "local") {
      try {
        await queueLaunchRequest()
      } catch (err) {
        setLaunchRequestError(err)
      }
    }
  }

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus)
  }, [status, streamerStatus])


  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <p>
          Your client has one or more configuration errors. Please consult the{" "}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client">
            {" "}
            README{" "}
          </a>{" "}
          for details on how to configure the client template.
        </p>
      </div>
    )
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <span>The model that you have requested does not exist</span>
      </div>
    )
  }

  if (launchRequestError) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <span>
          {process.env.NODE_ENV === "development"
            ? `There was an error with the launch request: ${launchRequestError}`
            : "It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available."}
        </span>
      </div>
    )
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h2>Disconnected from stream</h2>
      </div>
    )
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h2>Failure during stream</h2>
        <br></br>
        <h2>Please refresh to request a new session</h2>
      </div>
    )
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h2>Streamer contribution withdrawn</h2>
      </div>
    )
  }

  if (loading) {
    return (
      <EmbeddedView
        VideoStream={videoStream}
        StreamerStatus={streamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents}
        UsePointerLock={clientOptions.UsePointerLock}
        PointerLockRelease={clientOptions.PointerLockRelease}
      />
    )
  } else if (clientOptions.LaunchType !== "local" && !availableModels) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h2 className="pulse">INITIALIZING</h2>
      </div>
    )
  } else if (clientOptions.LaunchType !== "local" && !availableModels?.length) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h2>No models are currently available in this environment.</h2>
      </div>
    )
  } else {
    return <LaunchView Launch={launch} />
  }
}

const AppWrapper = () => {

  return System.IsBrowserSupported() && isBrowser ? (
    <>
      <img alt="logo1" preload className="logo-home" src={logoBlack}></img>
      <img alt="logo2" preload className="logo-home-left" src={logoBlack1}></img>
      <App />
    </>
  ) : (
    <div className="ui red segment center aligned basic" style={{ height: "100%", border: "none!important", width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <video className="logo" style={{ width: 400 }} src={video} loop muted autoplay="true" />
      <h3 className="header">PLEASE ACCESS THE GALLERY VIA DESKTOP FOR OPTIMAL EXPERIENCE</h3>
    </div>
  )
}

export default AppWrapper
