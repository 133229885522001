import React from "react"
import { Button } from "semantic-ui-react"

import "./Launch.css"
import clientConfig from "./client.json"
import logo from "./images/Shoe_8Bit_compressed.gif";

const client = clientConfig

class ClientJson {
  description = "description"
  title = "title"
}

export const LaunchView = props => {
  return (
    <div id="launchContainer">
      <div>
        {/* <h1>WELCOME TO THE DEEPOBJECTS GALLERY</h1> */}
        <Button
          className="button-launch"
          onClick={() => props.Launch()}
        >
          ENTER
        </Button>
      </div>
    </div>
  )
}
